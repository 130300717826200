import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./ServiceDetails.css";
import PartnerSlider from "../Home/PartnerSlider";
import netflix from "../../assets/images/case study.png";
import ScrollToTopButton from "../TopButton/TopButton";
import { Link } from "react-router-dom";

function SEO() {
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <div>
      <Helmet>
        <title>
          Transform Your Brand with SEO Services in India - The Agency Way (TAW)
        </title>
        <meta
          name="description"
          content="Elevate your brand's visibility and authority with expert SEO services from The Agency Way (TAW). Drive traffic, increase conversions, and achieve your business goals."
        />
        <link rel="canonical" href="https://taw.agency/services/seo" />
      </Helmet>
      <div className="container">
        {/* <div className="row"> */}
          {/* <div className="col-lg-4 col-md-4 col-12 mt-lg-5">
            <img height="350px" width="350px" src={img} alt="SEO" />
          </div> */}
          <div className="mt-5">
            <h1 className="service-details-title">
              Transform Your Brand with SEO Services in India
            </h1>
            <p className="paragraph-text">
             The digital landscape is constantly evolving, and it is here that standing out requires more than just strategies; it demands a story. For <a href="https://taw.agency/" target="_blank" rel="noopener noreferrer">The Agency Way</a>, storytelling powers connect, engage, and convert. Our full-scope digital marketing agency has the potential to give your brand and website the visibility and authority they deserve. From comprehensive SEO audits to proven optimization techniques, TAW ensures that your website becomes a hot spot for high traffic, increased CTR, and, ultimately, more conversions. With digitization at our core, we provide you with one-stop solutions, from hyperlocal SEO expertise to E-A-T-aligned content, to build credibility and innovative approaches to help you achieve your business goals.
            </p>
            <p className="paragraph-text">
              Let us craft a narrative that resonates with your audience and builds your brand’s legacy. With a result-oriented mindset, our strategies not only boost engagement but also translate your efforts into measurable revenue. Ready to be the hero of your industry’s story? Partner with us today and let’s create a digital success story together!
            </p>
            <h2 className="service-details-subtitle">Boost Traffic, Sales, and Leads with Expert SEO Services</h2>
            <ul className="paragraph-text">
              <li className="li-text"><strong>Keyword Research:</strong> The right words make all the difference. Our keyword research is not a reflection of the trends; instead, we dig deeper to uncover the terms your audience is looking for. From niche-specific phrases to competitive power words, we develop a strategy that drives traffic, increases engagement, and sparks conversions. Speak your audience's language and turn every search into an opportunity for growth!</li>
              <li className="li-text"><strong>Website Audit:</strong> Unlock your website’s true potential with a comprehensive audit. We dive deep into performance, SEO, and user experience to identify what’s working and what’s not. From fixing hidden issues to optimizing for search engines, our audits lay the foundation for stronger traffic, better engagement, and higher conversions. Let’s turn insights into impact!</li>
              <li className="li-text"><strong>Traffic Generation:</strong> Fuel your digital growth with strategies that attract, engage, and convert. From SEO to targeted campaigns, we bring your ideal audience straight to your site, turning visits into valuable actions. Let’s get the traffic flowing!</li>
              <li className="li-text"><strong>Competitive Analysis:</strong> Discover what sets you apart. Our competitive analysis uncovers market trends, rivals' strategies, and growth opportunities, giving your brand the edge it needs to lead. Outsmart, outperform, and outshine the competition!</li>
              <li className="li-text"><strong>Technical SEO:</strong> Optimize your website’s backbone with expert technical SEO. From faster load times to seamless crawling, we ensure search engines and users love your site. Power up your performance and climb the rankings effortlessly!</li>
              <li className="li-text"><strong>Search Engine Recovery:</strong> Lost rankings? We’ve got you covered. Our search engine recovery strategies identify issues, rebuild trust, and restore your website’s visibility. Let’s get you back on top where you belong!</li>
            </ul>
            <p className="paragraph-text">Want the same for your Business?</p>
            <p className="paragraph-text">How about catching up for a Coffee!</p>
          </div>
        {/* </div> */}
      </div>
      <div className="influ-reel">Our Clients </div>
      <PartnerSlider />
      <div className="influ-reels">Case Study </div>
      <div
        className="slider"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="slider-container"
          style={{ animationPlayState: isPaused ? "paused" : "running" }}
        >
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
        </div>
      </div>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Link to="/contact" className="btn-flip mt-4">
          <button className="req-btn">
            <i className="animation"></i>GET IN TOUCH<i className="animation"></i>
          </button>
        </Link>
      </div>
      <h2 className="faq_hed">Frequently Asked Questions (FAQs)</h2>

      <details>
        <summary>Why is SEO important for all types of business?</summary>
        <ul>
          <li>
            SEO is crucial for businesses as it improves online visibility, making your brand easily discoverable. In the digital-first world, consumers rely on search engines for products, services, or information. A well-optimized website helps rank higher in search results, driving targeted traffic and increasing credibility. SEO connects businesses with the right audience at the right time, boosts brand awareness, generates leads, and improves conversions, making it essential for sustainable market growth.
          </li>
        </ul>
      </details>
      <details>
        <summary>Does SEO service work for all search engines?</summary>
        <ul>
          <li>
            Yes, SEO services are universally applicable across search engines like Google, Bing, and Yahoo, as they follow core principles like keyword optimization, website performance improvement, user experience enhancement, and quality backlink building. However, strategies can be customized to meet specific search engine requirements for maximum visibility and reach. This allows businesses to connect with diverse audiences and capitalize on growth opportunities by tailoring SEO services to different search engines.
          </li>
        </ul>
      </details>
      <details>
        <summary>What is the cost of SEO services in India?</summary>
        <ul>
          <li>
            SEO services in India can be expensive depending on factors like the scope, website complexity, industry competition, and the provider's experience. On average, small to medium-sized businesses can expect monthly SEO services from ₹5,000 to ₹50,000, with more extensive services potentially costing ₹1,000 or more. Some agencies also offer project-based pricing for specific SEO tasks. It's crucial to choose a package that aligns with your goals, whether it's local SEO, technical SEO, or a full-scale digital marketing strategy.
          </li>
        </ul>
      </details>
      <details>
        <summary>Why should one choose TAW to be the top SEO Company in India?</summary>
        <ul>
        <li>
          TAW is a top <a href="https://taw.agency/services/seo" className="clickable-text" target="_blank" rel="noopener noreferrer">
          SEO company in India
         </a>, known for its exceptional results for businesses across various industries. With a team of skilled SEO experts, they use innovative strategies, 
          cutting-edge tools, and a deep understanding of search engine algorithms to boost online visibility and organic traffic. TAW's customer-centric approach 
          ensures tailored solutions for clients, improving rankings, conversions, and brand awareness. Their transparent reporting, ethical SEO practices, and 
          consistent results make them a trusted partner for sustainable digital growth and long-term success.
         </li>
        </ul>
      </details>
      <ScrollToTopButton />
    </div>
  );
}

export default SEO;