import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./ServiceDetails.css";
import PartnerSlider from "../Home/PartnerSlider";
import netflix from "../../assets/images/case study.png";
import ScrollToTopButton from "../TopButton/TopButton";
import { Link } from "react-router-dom";

function MediaPlanning() {
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <div>
      <Helmet>
        <title>
          Creative Graphic Design Agency in Gurgaon - The Agency Way (TAW)
        </title>
        <meta
          name="description"
          content="Transform your brand's visual identity with The Agency Way (TAW), your leading creative graphic design agency in Gurgaon. Boost your brand's aesthetics with our innovative solutions"
        />
        <link rel="canonical" href="https://taw.agency/services/design" />
      </Helmet>

      <div className="container">
        {/* <div className="row">
          <div className="col-lg-4 col-md-4 col-12 mt-lg-5">
            <img height="350px" width="350px" src={img} alt="Design" />
          </div> */}
         <div className="mt-5">
  <h1 className="service-details-title">
    Unleashing Creativity at The Agency Way (TAW)
  </h1>
  <p className="paragraph-text">
    Welcome to the creative epicenter, where imagination meets strategy. At <a href="https://taw.agency/" target="_blank" rel="noopener noreferrer">The Agency Way (TAW)</a>, we breathe life into ideas through a blend of digital and offline wonders that capture attention and deliver significant results. A strong visual or story defines what makes a brand memorable. Let’s collaborate and create magic, where each design speaks volumes and each idea drives your brand toward success.
</p>

  <h2 className="service-details-subtitle">
    Design Services
  </h2>
  
  <h5>Poster Design</h5>
  <p className="paragraph-text">
  Transform your message into a masterpiece with our poster designs. Bold, eye-catching, and strategically crafted, our posters ensure your brand's voice is heard loud and clear in any space.
  </p>
  <h5>Infographic Design</h5>
  <p className="paragraph-text">
  Turn complex data into visual brilliance! Our infographics are designed to inform, engage, and captivate audiences, making even the most intricate details easy to grasp and impossible to ignore.  </p>

  <h5>Website & App Banner Design</h5>
  <p className="paragraph-text">
  Capture attention at first glance! Our website and app banners blend sleek aesthetics with compelling messaging to create visuals that drive clicks and conversions effortlessly.
  </p>

  <h5>Marketing Collaterals Design</h5>
  <p className="paragraph-text">
  From brochures to flyers, we design marketing materials that resonate with your audience, enhancing your brand's impact across every touchpoint.
  </p>

  <h5>Social Media Creatives Design</h5>
  <p className="paragraph-text">
  Scroll-stopping designs for the digital age! Our <a href="https://taw.agency/services/socialmedia" target="_blank" rel="noopener noreferrer">social media creatives</a> combine trend-savvy aesthetics with a unique brand story, ensuring every post leaves a mark.
</p>


  <h5>Brand Identity Design</h5>
  <p className="paragraph-text">
  Define your brand with designs that echo its essence. From logos to complete brand kits, we create timeless identities that establish a strong foundation for your success.
  </p>

  <p className="paragraph-text">
    At TAW, creativity knows no bounds. Ready to transform your brand with designs that leave a lasting impression? Let’s make it happen!
  </p>
</div>

        {/* </div> */}
      </div>
      <div className="influ-reel">Our Clients </div>
      <PartnerSlider />
      <div className="influ-reels">Case Study </div>
      <div
        className="slider"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="slider-container"
          style={{ animationPlayState: isPaused ? "paused" : "running" }}
        >
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
        </div>
      </div>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Link to="/contact" class="btn-flip mt-4">
          <button class="req-btn">
            <i class="animation"></i>GET IN TOUCH<i class="animation"></i>
          </button>
        </Link>
      </div>
      <h2 className="faq_hed">Frequently Asked Questions (FAQs)</h2>

      <details>
        <summary>
          What role does Design play in creating brand's identity?
        </summary>
        <ul>
          <li>
            Design helps your audience recognize your brand beyond your brand's
            name. Using colors, theme, font and typography language, you can
            imprint your brand on your audience.
          </li>
        </ul>
      </details>
      <details>
        <summary>
          How to add creative & design to communicate Brand's message?
        </summary>
        <ul>
          <li>
            Color, themes, fonts, illustration has a strong way of portraying
            your brand's image into the audience mind. Using designing
            techniques to communicate message can create lasting impression.
          </li>
        </ul>
      </details>
      <details>
        <summary>What is the most used designing software?</summary>
        <ul>
          <li>
            Photoshop is the most general software for designing used by
            billions of people. Apart from that, Adobe Suite offers all the
            designing software and elements to craft your Brand's design and
            identity.
          </li>
        </ul>
      </details>
      <details>
        <summary>How to build brand identity through design language?</summary>
        <ul>
          <li>
            A set of color, theme, font, mood board can help you build your
            brand identity through design language.{" "}
          </li>
        </ul>
      </details>
      <ScrollToTopButton />
    </div>
  );
}

export default MediaPlanning;
