import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./ServiceDetails.css";
import PartnerSlider from "../Home/PartnerSlider";
import netflix from "../../assets/images/case study.png";
import ScrollToTopButton from "../TopButton/TopButton";
import { Link } from "react-router-dom";

function Influencer() {
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <div>
      <Helmet>
        <title>
          Influencer-marketing Agency in Gurgaon - The Agency Way (TAW)
        </title>
        <meta
          name="description"
          content="Empower your brand's influence with The Agency Way (TAW), your premier influencer marketing agency in Gurgaon. Collaborate with top influencers and amplify your brand's reach today."
        />
        <link
          rel="canonical"
          href="https://taw.agency/services/influencer-marketing"
        />
      </Helmet>
      <div className="container">
        {/* <div className="row"> */}
          {/* <div className="col-lg-4 col-md-4 col-12 mt-lg-5">
            <img
              height="350px"
              width="350px"
              src={img}
              alt="Influencer Marketing"
            />
          </div> */}
          <div className="mt-5">
  <h1 className="service-details-title">
    Revolutionise Your Business With Influencer Marketing
  </h1>
  <p className="paragraph-text">
    Influencer marketing is emerging as one of the most potent tools for all brands in this ever-changing digital world. It’s not just about promoting a fantastic product but about strategic collaborations with the right influencers. With the <a href="https://taw.agency/services/socialmedia" target="_blank" rel="noopener noreferrer">social media</a> landscape becoming crowded, brands struggle to find the right fit, while influencers have limited access to collaborate with brands. Our services bridge this gap by offering in-depth influencer marketing solutions that help brands and influencers collaborate effectively.
  </p>
  <p className="paragraph-text">
    Through our expertise, we connect brands with the right influencers, create successful campaigns, and amplify brand messages. We enable influencers to access tailor-made opportunities and elevate their portfolios. Let us guide you through the fast-paced world of influencer marketing to ensure you achieve measurable results.
  </p>
  
  <h2 className="service-details-subtitle">
    Why Choose Influencer Marketing? Key Benefits Unveiled
  </h2>
  <h5>Transform Your Brand Awareness</h5>
  <p className="paragraph-text">
    Influencer marketing makes it easier for your brand to catch the attention of a mass audience, showcasing your brand through dynamic and engaging content.
  </p>
  <h5>Deliver Relevant & Engaging Content</h5>
  <p className="paragraph-text">
    With influencers targeting niche audiences, you can deliver highly relevant and creative content that resonates with your target market.
  </p>
  <h5>Build Trust & Credibility Instantly</h5>
  <p className="paragraph-text">
    Influencers are trusted voices. When they endorse your products or services, their followers are more likely to convert into loyal customers.
  </p>
  <h5>Offer Value to the Audience</h5>
  <p className="paragraph-text">
    Influencer marketing goes beyond promotion by offering real value to the audience through well-thought-out posts, creative videos, and eye-catching graphics.
  </p>
  <h5>Expand Your Reach Exponentially</h5>
  <p className="paragraph-text">
    By partnering with influencers, you tap into their vast network, expanding your reach to potential customers and increasing engagement.
  </p>
  <h5>Build Lasting Partnerships</h5>
  <p className="paragraph-text">
    Influencer marketing is not just about a one-time campaign; it's the start of a long-term strategic partnership that benefits both brands and influencers.
  </p>
  
  <h2 className="service-details-subtitle">
    Top Features of Our Influencer Marketing Services
  </h2>
  <p className="paragraph-text">The Agency Way  <a href="https://taw.agency/services/influencer-marketing" target="_blank" rel="noopener noreferrer">influencer marketing services</a> are built to add value to brands and influencers. While brand visibility increases, interesting collaborations on value projects that interest the influencers keep surfacing.
  </p>
  <h5>Effortless Onboarding for Influencers and Brands</h5>
  <p className="paragraph-text">
    We ensure a seamless onboarding process for both influencers and brands, making collaborations easy and impactful.
  </p>
  <h5>Match the Right Brands with the Right Influencers</h5>
  <p className="paragraph-text">
    Our services link brands to the perfect influencers based on vision, audience, and goals, ensuring successful partnerships.
  </p>
  <h5>Streamlined Content Approval and Sharing</h5>
  <p className="paragraph-text">
    Our prompt content approval system eliminates delays, ensuring quick turnarounds and efficient social media campaigns.
  </p>
  <h5>Advanced Targeting and Insights</h5>
  <p className="paragraph-text">
    Our platform offers advanced filtering and deep analytics, helping brands make strategic decisions based on influencer performance.
  </p>

  <p className="paragraph-text">
  Let's experience the magic behind <a href="https://taw.agency/" target="_blank" rel="noopener noreferrer">TAW</a> influencer marketing services – meaningful connections, impactful campaigns, and measurable results. Let us help you rethink your success.
</p>

</div>

        {/* </div> */}
      </div>
      <div className="influ-reel">Our Clients </div>
      <PartnerSlider />
      <div className="influ-reels">Case Study </div>
      <div
        className="slider"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="slider-container"
          style={{ animationPlayState: isPaused ? "paused" : "running" }}
        >
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
        </div>
      </div>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Link to="/contact" class="btn-flip mt-4">
          <button class="req-btn">
            <i class="animation"></i>GET IN TOUCH<i class="animation"></i>
          </button>
        </Link>
      </div>
      <h2 className="faq_hed">Frequently Asked Questions (FAQs)</h2>

      <details>
        <summary>
          Why should you develop a website according to industry trends?
        </summary>
        <ul>
          <li>
            Developing and maintaining your website according to ongoing trends
            is the best way to remain competitive in your business niche.
          </li>
        </ul>
      </details>
      <details>
        <summary>Can you work on a previously built website?</summary>
        <ul>
          <li>Yes, we can work on an existing website developed previously.</li>
        </ul>
      </details>
      <details>
        <summary>
          Why is it imperative to have a good website flow and design?
        </summary>
        <ul>
          <li>
            Layout plays a huge role in your website’s page. Without it, your
            website is a wall of text and links that few would care to read
            through. Layout is important because it influences many different
            things.
          </li>
        </ul>
      </details>
      <details>
        <summary>Why UI/UX is important for the website?</summary>
        <ul>
          <li>
            Responsive design teams create a single site to support many
            devices, but need to consider content, design and performance across
            devices to ensure usability.
          </li>
        </ul>
      </details>
      <ScrollToTopButton />
    </div>
  );
}

export default Influencer;
