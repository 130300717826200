import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./ServiceDetails.css";
import PartnerSlider from "../Home/PartnerSlider";
import netflix from "../../assets/images/case study.png";
import ScrollToTopButton from "../TopButton/TopButton";
import { Link } from "react-router-dom";

function Programmatics() {
  const [isPaused, setIsPaused] = useState(false);
  const handleMouseEnter = () => {
    setIsPaused(true);
  };
  const handleMouseLeave = () => {
    setIsPaused(false);
  };
  return (
    <div>
      <Helmet>
        <title>Paid Advertising Agency in Gurgaon - The Agency Way (TAW)</title>
        <meta
          name="description"
          content="Accelerate your brand's growth with The Agency Way (TAW), your premier paid advertising agency in Gurgaon. Reach your target audience with Maximize ROI."
        />
        <link rel="canonical" href="https://taw.agency/services/paid-ads" />
      </Helmet>
      <div className="container">
        {/* <div className="row">
          <div className="col-lg-4 col-md-4 col-12 mt-lg-5">
            <img height="350px" width="350px" src={img} alt="Paid Ads" />
          </div> */}
          <div className="mt-5">
  <h1 className="service-details-title">
    Unleashing the Power of PPC with The Agency Way (TAW)
  </h1>
  <p className="paragraph-text">
  The Agency Way (TAW), the digital marketing agency in Gurgaon, is one where we redefine excellence in PPC management services by delivering solutions that work specifically for any business or industry. We're all about the simple yet great mission: help you get exponentially higher with quality leads, visibility to your brand, and higher sales.
  </p>
  <p className="paragraph-text">
  We have years of experience and expertise under our belt. Our team is perfecting the art of crafting result-driven PPC campaigns, so we're proud to say that we truly understand each client's goals and develop strategies that meet expectations and surpass them. Collaborate with us and achieve unmatched ROI, immediate impact, and long-term business growth. Let TAW be your catalyst for success, and see your brand soaring to new heights.  </p>

  <h2 className="service-details-subtitle">
    Maximize Conversion & ROI with Our Expert PPC Solutions
  </h2>
  <p className="paragraph-text">
    Success in PPC calls for something other than running ads. It is based on precision, strategy, impact, and real-time progression. It begins with choosing the best keywords for your ads, designing interesting landing pages, and more. TAW offers high-class <a href="https://taw.agency/services/paid-ads" target="_blank" rel="noopener noreferrer">PPC services in India</a> to captivate your target audience and drive significant traffic volumes to your businesses.
</p>

  <p className="paragraph-text">
     Our team focuses on creating targeted PPC campaigns per the specific business needs and budget, from creating copy ads to monitoring the performance by sending detailed reports, ensuring smoothness and fruitful delivery.
  </p>
  <p className="paragraph-text">
    With an in-depth understanding of PPC advertising nuances, we have assisted hundreds of businesses in unlocking their full potential by ensuring guaranteed online success. From search ads and display campaigns to any other format, our experience will ensure you achieve impactful outcomes every time.  </p>
  <h2 className="service-details-subtitle">
    Grow Your Business with Smart PPC Management Services in India
  </h2>
  
  <h5>Targeted Paid Search Management</h5>
  <p className="paragraph-text">
  We help your ads reach the right audience by implementing precision keyword strategies. We help optimize your ads and landing pages to get the maximum possible click-through and conversion rates.
  </p>

  <h5>Reconnect with Remarketing</h5>
  <p className="paragraph-text">
  Rekindle interest with niche remarketing campaigns that target potential visitors who've already visited your website. We help you turn inquisitiveness into conversions by targeting those who have already demonstrated interest in your offerings.  </p>

  <h5>Strategic Paid Social Advertising</h5>
  <p className="paragraph-text">
  Unlock the power of platforms with paid social through our specialized services, including Facebook, Instagram, and YouTube. We develop attention-grabbing, tailored advertising that engages your audience with relevant results.
  </p>

  <h5>High-Impact PPC Landing Pages</h5>
  <p className="paragraph-text">
  The correct conversion starts with the proper landing page. Our designers create a design that will resonate with your target audience, so the layout and content will convert the visitors into loyal customers.
  </p>

  <h5>Dynamic Display Advertising</h5>
  <p className="paragraph-text">
   Eye-catching display ads appeal to the preferences of your audience. Our expertise ensures the visibility of your brand and a wider circle of customers.
  </p>

  <h5>Comprehensive PPC Audits</h5>
  <p className="paragraph-text">
  Get the best of our review of campaigns. We check your data, identify areas of improvement, and add actionable insights to help you reach the most significant ROI.
  </p>

  <h2 className="service-details-subtitle">
    Mastering Ads with Our PPC Expertise
  </h2>
  <p className="paragraph-text">
  Our experts carefully monitor your PPC campaigns and identify improvements. With the review, we arm you with actionable and strategy to improve your campaigns to maximize ROI. Whether perfecting keyword strategies or landing pages, our experts will arm you with the proper tools for successful PPC.
  </p>

  <h5>Facebook Ad Solution</h5>
  <p className="paragraph-text">
   As a leader in pay-per-click management companies, we work on creating a Facebook ad campaign that enables businesses to reach their target markets. From ad copywriting and design to getting views to convert into clicks to clicks into conversion, we take pride in performing well on this interactive platform.
  </p>

  <h5>Social Media Advertising Solutions</h5>
  <p className="paragraph-text">
    Raise your online presence with our customized social media advertising services. Our team creates engaging content and develops strategic ad campaigns to enhance your brand's visibility further, drive traffic to your website, and engage customers further.
    </p>
    
  <h5>Google Shopping Ad Expertise</h5>
  <p className="paragraph-text">
  Capture high-intent shoppers with Google Shopping Ads displayed right at the top of the search result in Google. These will display your products and prices directly to users so that their journey to buying is streamlined. We assist the eCommerce business in finding a niche target audience and maximizing conversions, helping them thrive with our PPC expertise.
    </p>

  <h2 className="service-details-subtitle">
  Ads Come Under Our Forte While Impacting PPC Services
  </h2>
  
  <h5>Accessible Entry Point</h5>
  <p className="paragraph-text">
  One can quickly start with PPC with almost no initial setup and investment; it suits even the most resource-sensitive businesses. A few hours may go by while formulating relevant ad copy, assigning budgets, and then witnessing its benefits for that highly effective marketing weapon.
  </p>

  <h5>Short-Term Promotion Power</h5>
  <p className="paragraph-text">
  PPC campaigns are a perfect solution for businesses looking to promote time-sensitive offers. You can avoid long-term strategies and create focused campaigns highlighting your current deals and driving immediate results.
  </p>

  <h5>Enhanced Brand Recognition</h5>
  <p className="paragraph-text">
  PPC ads ensure that your website is placed in the front row of search engine results, thereby maximizing clicks. This would provide more traffic but, at the same time, establish credibility for your brand on the internet.
    </p>

  <h5>Instant and Reliable Traffic</h5>
  <p className="paragraph-text">
  A well-optimized PPC campaign ensures you get visitors immediately, which means chances for sales and conversions are good. Maintaining your ad spend will ensure that, over time, you get the traffic consistently flowing in.
    </p>

  <h5>Precision Targeting, Cost-Effectively</h5>
  <p className="paragraph-text">
  PPC marketing allows the focus on an ideal target market by reaching targeted demographics, locations, and interests, thus increasing conversions without wasting more on advertising spend.
  </p>

  <h5>Diverse Exposure Channels</h5>
  <p className="paragraph-text">
  Expand your reach to other networks through <a href="https://taw.agency/services/socialmedia" target="_blank" rel="noopener noreferrer">social media</a> like Facebook and Instagram through PPC campaigns, which opens up otherwise inaccessible routes for engagement and enhances visibility and conversion.
</p>

  <p className="paragraph-text">
  Let every click count! Let's partner and elevate your brand to the top with precision-focused PPC marketing!
</p>
</div>

        {/* </div> */}
      </div>
      <div className="influ-reel">Our Clients </div>
      <PartnerSlider />
      <div className="influ-reels">Case Study </div>
      <div
        className="slider"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="slider-container"
          style={{ animationPlayState: isPaused ? "paused" : "running" }}
        >
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
        </div>
      </div>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Link to="/contact" class="btn-flip mt-4">
          <button class="req-btn">
            <i class="animation"></i>GET IN TOUCH<i class="animation"></i>
          </button>
        </Link>
      </div>
      <h2 className="faq_hed">Frequently Asked Questions (FAQs)</h2>
      <details>
        <summary>
          Why should you develop a website according to industry trends?
        </summary>
        <ul>
          <li>
            Developing and maintaining your website according to ongoing trends
            is the best way to remain competitive in your business niche.
          </li>
        </ul>
      </details>
      <details>
        <summary>Can you work on a previously built website?</summary>
        <ul>
          <li>Yes, we can work on an existing website developed previously.</li>
        </ul>
      </details>
      <details>
        <summary>
          Why is it imperative to have a good website flow and design?
        </summary>
        <ul>
          <li>
            Layout plays a huge role in your website’s page. Without it, your
            website is a wall of text and links that few would care to read
            through. Layout is important because it influences many different
            things.
          </li>
        </ul>
      </details>
      <details>
        <summary>Why UI/UX is important for the website?</summary>
        <ul>
          <li>
            Responsive design teams create a single site to support many
            devices, but need to consider content, design and performance across
            devices to ensure usability.
          </li>
        </ul>
      </details>
      <ScrollToTopButton />
    </div>
  );
}

export default Programmatics;
