import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./ServiceDetails.css";
import PartnerSlider from "../Home/PartnerSlider";
import netflix from "../../assets/images/case study.png";
import ScrollToTopButton from '../TopButton/TopButton';
import { Link } from "react-router-dom";


function SocialMedia() {
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <div>
      <Helmet>
        <title>Best Social Media Marketing Agency in Gurgaon - The Agency Way (TAW)</title>
        <meta name="description" content="Maximize your social media presence with The Agency Way (TAW), your top choice for social media marketing in Gurgaon. Engagement and growth your brand today." />
        <link rel="canonical" href="https://taw.agency/services/socialmedia" />
      </Helmet>
      <div className="container">
        {/* <div className="row">
          <div className="col-lg-4 col-md-4 col-12 mt-lg-5">
            <img height="350px" width="350px" src={img} alt="SocialMedia" />
          </div> */}
         <div className="mt-5">
  <h1 className="service-details-title">
    Get Your Hands on Ideal Social Media Marketing Services in India to Uplift Your Business
  </h1>
  <p className="paragraph-text">
    Unlock the real power of social media with marketing strategies designed to give your brand that winning edge. Social Media Marketing takes full advantage of the great reach these platforms offer in increasing brand awareness, website traffic, and, ultimately, sales. We work out all the nuances - from active engagement on social media to analytics based on data.
  </p>
  <p className="paragraph-text">
    Our mission? Simple and to the point. Delivering satisfaction by transforming your brand into a market leader. If you’re ready to make an impact and leave a mark, our dedicated team is here to guide your journey every step of the way.
  </p>

  <h2 className="service-details-subtitle">
    Boost Your Brand with Us: Your Pathway to Revenue Growth
  </h2>
  <p className="paragraph-text">
   The world has embraced this new age of marketing that will take the game beyond traditional modes into highly dynamic social media. Staying ahead in the ever-changing game of market business requires a form of adaptation. Social Media Marketing is what unlocks this very change. <a href="https://taw.agency/" target="_blank" rel="noopener noreferrer">The Agency Way</a> presents you with a social media marketing service that can supercharge your brand and help take your business through the roof.
  </p>
  <p className="paragraph-text">
   Our social media maestros are dedicated to delivering customized solutions that best connect you with your audience. Accessing customer preference insights and behaviour, we make sure that our strategies precisely bring about your desired reach and accomplishment.  </p>
  <p className="paragraph-text">
   A perfect mix of strategy and creativity is what ensures that your social media presence turns into a weapon for success. With this power, let The Agency Way empower your online media and open routes for unprecedented expansion. Trust it to our proficiency and get closer to the limitless extent of social network usage.
  </p>

  <h2 className="service-details-subtitle">
    Unlock Digital Success with Our Holistic Marketing Approach
  </h2>

  <h5>Search Engine Optimization (SEO)</h5>
  <p className="paragraph-text">
    Ramp up with comprehensive SEO and social networking advertising services from The Agency Way (TAW) to increase involvement online and generate more users around you. Our professionals here are dedicated to creating the finest SEO-friendly content with rigorous keyword research so it meets the intent for maximum organic traffic. Experts additionally develop appealing videos, infographics, and image content that attract more engagement among users and redirect them to the website.
  </p>

  <h5>PPC Advertising</h5>
  <p className="paragraph-text">
    Take advantage of TAW's strategic social media <a href="https://taw.agency/services/paid-ads" target="_blank" rel="noopener noreferrer">PPC services</a> that will maximize your impact from running PPC campaigns. We dig into knowing your target audience, look for high-performance keywords, and come up with significant sponsored updates. We further integrate eye-catching visuals, videos, and the proper captions so you can see a higher return on your ad investment across these social media channels.
  </p>

  <h5>Website Designing and Development</h5>
  <p className="paragraph-text">
   We merge consistency with creativity at TAW. We can also get social proof by including reviews and ratings right on your website. In addition to that, we also put up social sharing icons and live feeds on your site so visitors do not go dormant but get stirred into action by seeing how vibrant your site really is compared to the world on your social networks.  </p>

  <h5>Content Creation</h5>
  <p className="paragraph-text">
    Quality marketing content is genuinely insightful and value-driven. While covering websites of social media giants such as Instagram, Twitter, and LinkedIn, TAW ensures that the right voice of the brand resonates across all media. We work to deliver high-quality content for education, engagement, and conversion that is meant to amplify reach and marketing. 
 </p>

  <h5>Link Building</h5>
  <p className="paragraph-text">
    Help strengthen your digital footprint with TAW's link-building prowess, where we work through various strategies like LinkedIn marketing, YouTube, and other niche bloggers or influencers that help you build quality backlinks to drive traffic and lend more authority to your website.
  </p>

  <h2 className="service-details-subtitle">
  Transform Your Social Media Presence with TAW Specialised Marketing Solutions  </h2>

  <h5>Instagram Marketing</h5>
  <p className="paragraph-text">
    Develop meaningful connections and build a living community around your brand with our Instagram marketing services. Improve your business profile and launch a highly engaging, relevant, and fascinating sponsored ad for users. Using reels and stories on Instagram can tell your stories. We ensure your presence on the site is both engaging and impactful. Let insights guided by analytics steer your brand forward with us.
  </p>

  <h5>Facebook Marketing</h5>
  <p className="paragraph-text">
   Leverage the affordability and reachability of Facebook marketing to strengthen relationships with your customer base and make traffic to the website. Let us at TAW frame strategies that resonate with your budgets, manage profiles, optimize advertisements, and execute performance analysis. We will upgrade your brand visibility while making it seem effortless to boost customer engagement at The Agency Way.
  </p>

  <h5>LinkedIn Marketing</h5>
  <p className="paragraph-text">
   With our LinkedIn marketing services, you can establish a professional reputation for your brand. We at TAW work toward crafting strategies that are impactful and unique that differentiate your brand from competitors and will manage everything - your company page, valuable posts, connection-making with influential people in industries.
  </p>

  <h5>Twitter Marketing</h5>
  <p className="paragraph-text">
   Make your brand buzzworthy with targeted Twitter marketing. Through advanced analytics, The Agency Way identifies and gets the ideal audience, makes compelling content, and designs effective strategies for traffic and engagement. Our team can do that for you, working to create a road map based on business objectives and setting your brand on top again.
  </p>

  <h2 className="service-details-subtitle">
    What Our Experts Can Bring to Your Business
  </h2>

  <h5>Boosted Online Visibility</h5>
  <p className="paragraph-text">
   You are now a finger away from Facebook, Twitter, and LinkedIn. Increased exposure for your company has never been this easy. A successful social media strategy can produce lead engagement and build relationships with diverse audiences while creating buzz about you online.  
  </p>

  <h5>Targeted Audience Precision</h5>
  <p className="paragraph-text">
   Even the best content needs an appropriate audience to hit the bull's eye. Here at The Agency Way, our experts get to know the specific audience and customize your content strategies based on the exact needs that strike the audience so your message hits home to whom it matters.
  </p>

  <h5>Improved Search Engine Rankings</h5>
  <p className="paragraph-text">
    Activity through social media can influence the rank in search engines. As a result of activity through sharing content and engaging, platforms like Google and Bing also pay attention, driving more traffic to your site. The Agency Way helps your content grow in visibility and authority, strengthening your online presence.
 </p>

  <h5>Customizable Brand Management</h5>
  <p className="paragraph-text">
   You get complete control over the voice, budget, and social media presence of your brand with personalized packages from us. We constantly adjust our strategy to match your goal and financial planning to empower you with impactful decision-making for your business.
  </p>

  <h5>Expanded Customer Reach</h5>
  <p className="paragraph-text">
    Social media decides everything from research about the product to influencer recommendations. TAW's strategists turn your brand into an albatross to be remembered and so, your company stays ahead in front of your ideal target audience.
  </p>

  <h5>Strengthened Customer Relationships</h5>
  <p className="paragraph-text">
   Trust building lies at the heart of a successful business. Our social media experts connect with your audience through value-added content and meaningful conversations to build long-term relationships that foster loyalty and growth.
  </p>
</div>

        {/* </div> */}
      </div>
      <div className="influ-reel">Our Clients </div>
      <PartnerSlider />
      <div className="influ-reels">Case Study </div>
      <div className="slider" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className="slider-container" style={{ animationPlayState: isPaused ? "paused" : "running" }}>
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
        </div>
      </div>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
      <Link to="/contact" class="btn-flip mt-4" >
          <button class="req-btn"><i class="animation"></i>GET IN TOUCH<i class="animation"></i>
          </button>
        </Link>
      </div>
      <h2 className="faq_hed">Frequently Asked Questions (FAQs)</h2>

      <details>
        <summary>
        Why do you need a social media marketing plan?
        </summary>
        <ul>
          <li>
          With social media becoming more important every day, there's a case for every business to exploit this platform for marketing. When you consider that 73% of marketers have found social media effective for their business, you’ll see that this is an ideal platform for boosting your revenue.
          </li>
        </ul>
      </details>
      <details>
        <summary>
        Can social media marketing be used for B2B business?
        </summary>
        <ul>
          <li>
          Yes, social media marketing can be used for a B2B brand. Effective B2B marketing requires a different approach. B2B marketers have to think more strategically to reach business owners and decision-makers through social media marketing. They then nurture relationships that can lead to large purchase agreements.
          </li>
        </ul>
      </details>
      <details>
        <summary>
        What are the benefits of social media advertising?
        </summary>
        <ul>
          <li>
          Social media helps a brand to create a brand identity, generate online traffic, get user-generated data, increase brand engagement with customers, and more.
          </li>
        </ul>
      </details>
      <details>
        <summary>
        Is social media good for lead generation?
        </summary>
        <ul>
          <li>
          Social media can be your most valuable lead-generation channel if you know how to use it right. With 3.6 billion people using social media worldwide, it’s the perfect channel for businesses to reach and attract leads. It’s only a matter of using the right tactics at the right time.          </li>
        </ul>
      </details>
      <ScrollToTopButton />
    </div>
  );
}

export default SocialMedia;
