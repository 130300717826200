import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./ServiceDetails.css";
import PartnerSlider from "../Home/PartnerSlider";
import netflix from "../../assets/images/case study.png";
import ScrollToTopButton from "../TopButton/TopButton";
import { Link } from "react-router-dom";

function Production() {
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <div>
      <Helmet>
        <title>
          Film and Photography Production Agency in Gurgaon - The Agency Way
          (TAW)
        </title>
        <meta
          name="description"
          content="Capture the essence of your brand with The Agency Way (TAW), your premier film and photography production agency in Gurgaon. Let your creativity captures the essence of your brand."
        />
        <link rel="canonical" href="https://taw.agency/services/production" />
      </Helmet>
      <div className="container">
        {/* <div className="row"> */}
          {/* <div className="col-lg-4 col-md-4 col-12 mt-lg-5">
            <img
              height="350px"
              width="350px"
              src={img}
              alt="Web & Design Development"
            />
          </div> */}
          <div className="mt-5">
  <h1 className="service-details-title">
    Bringing Your Stories to Life, Frame by Frame
  </h1>
  <p className="paragraph-text">
  In terms of creativity and communication, film and photography are the greatest storytellers. They can take us into the world of emotions, deliver messages, and, most importantly, in many cases, they leave their mark on our lives. Whether it is a meaningful advertisement, a great visual campaign, or an excellent narration, the beauty of film and photography can be brought out by bringing an idea to life.
  </p>

  <h2 className="service-details-subtitle">
    Why Film and Photography Matter
  </h2>
  <p className="paragraph-text">
    In today’s visual-driven culture, film and photography go beyond being mere mediums; they are essential communication tools for businesses, artists, and individuals alike. These visual forms are indispensable for several reasons.
  </p>

  <h5>Emotional Connection</h5>
  <p className="paragraph-text">
  Emotion works to connect any audience to a brand or story. <a href="https://taw.agency/services/production" target="_blank" rel="noopener noreferrer">Films and photos</a> give joy, nostalgia, excitement, or even empathy. These emotions bridge the message and the audience to make them remember the brand or story beyond the fading screen.
</p>

  <h5>Visual Storytelling</h5>
  <p className="paragraph-text">
    A picture speaks a thousand words, but a well-crafted video tells a thousand stories. Films have the ability to simplify complex narratives and deliver them creatively, making them more relatable and understandable to the audiences.
  </p>

  <h5>Enhanced Brand Identity</h5>
  <p className="paragraph-text">
  Excellent visuals underscore your professionalism and perfectionism, We interpret your brand's personality, making it easily relatable and memorable with lots of competition.
  </p>

  <h5>Boost Engagement</h5>
  <p className="paragraph-text">
  In the digital world, pictures are worth a thousand words, Videos and images will more effectively capture attention, engage people, and drive conversions than text.
  </p>

  <h5>Versatile Applications</h5>
  <p className="paragraph-text">
    Film and photography are easy to use in almost all things: a marketing campaign and product launch or a personal event and artistic endeavour.
  </p>

  <h2 className="service-details-subtitle">
    Our Services Include
  </h2>
  <p className="paragraph-text">
     We have a broad range of film and photography services designed to impact and bring value to our client's diverse needs.
  </p>

  <h5>Concept Development</h5>
  <p className="paragraph-text">
    Every great project starts with an idea. We help you develop a concept that meets your vision and has a sense of belonging among your audience. Be it a heart-touching story or a bold statement, we ensure your idea will stand out.
  </p>

  <h5>Scripting</h5>
  <p className="paragraph-text">
  A well-written script is the core of any successful video. Our experienced writers write inspiring, informative, and engaging stories to ensure your message resonates with the right people at the right time.
  </p>

  <h5>Storyboarding</h5>
  <p className="paragraph-text">
  Before rolling the camera, we create storyboards to develop the detailed visions you want on set. This ensures a clear visual roadmap and helps keep the project on track with your goals.
  </p>

  <h5>Filming</h5>
  <p className="paragraph-text">
  Using the finest equipment and state-of-the-art techniques, we take pictures that embody creativity and precision. Whether it is a corporate shoot, a lifestyle campaign or just an event, we give them something to remember you with.
  </p>

  <h5>Editing & Colour Grading</h5>
  <p className="paragraph-text">
    Post-production is where the magic happens. Our editors fine-tune the footage, add seamless transitions, and apply cinematic color grading to deliver visually stunning results that align with your vision.
  </p>

  <h5>Visual Effects & Animation</h5>
  <p className="paragraph-text">
  At other times, ideas need an extra layer of creativity. Visual effects and animations give imagination life and elevate a project to new heights.  </p>

  <h5>Photography</h5>
  <p className="paragraph-text">
  With professionals in headshots, product photography, event coverage, and even artistic portraits, our photographers see the details, beauty, and moments to create.
  </p>

  <h5>Drone Cinematography</h5>
  <p className="paragraph-text">
  Our cinematography of drones adds drama and perspective that makes your project come alive and gives it an unforgettable experience.
  </p>

  <h5>Post-Production</h5>
  <p className="paragraph-text">
  Every detail counts. Our post-production process guarantees a perfect finish - professional and ready to present before your target audience.
  </p>

  <h2 className="service-details-subtitle">
    Top Features of Our Film & Photography Services
  </h2>
  <p className="paragraph-text">
    We proudly provide high-end services to clients with diverse needs. Here’s what differentiates us:

  </p>

  <h5>Tailored Storytelling</h5>
  <p className="paragraph-text">
  Each project that we do is custom-fit for your brand's personality and value. Whether it is a sleek corporate video or a lifestyle campaign with the most energetic tone, we always ensure your story is authentic.
  </p>

  <h5>State-of-the-Art Technology</h5>
  <p className="paragraph-text">
  Our team employs the best cameras, lighting equipment, and editing software to achieve the best possible image quality for your project.
  </p>

  <h5>Collaborative Approach</h5>
  <p className="paragraph-text">
    We believe in close work collaboration with our clients. From idea generation right up to final delivery, your opinion will be respected and integrated at every single step.
  </p>

  <h5>Versatility in Style</h5>
  <p className="paragraph-text">
  No two projects are alike, and we design our style in accordance with what you want, whether it is to be minimalist, artistic, bold, or classic.
  </p>

  <h5>Quick Turnaround</h5>
  <p className="paragraph-text">
  We care about deadlines, too. Our systematic processes ensure the delivery of your project on time.
  </p>

  <h2 className="service-details-subtitle">
    Why Choose Us for Film & Photography
  </h2>
  <p className="paragraph-text">
  With a passion for creativity and a firm commitment to quality, <a href="https://taw.agency/" target="_blank" rel="noopener noreferrer">TAW</a> works to exceed your expectations. Our technical know-how and artistic creativity seamlessly complement each other in bringing your ideas to life, meaning that your project will be unique in a crazy, crowded marketplace.
</p>
  <p className="paragraph-text">
  From a cutting-edge ad film to recording some memorable event or creating an extraordinary digital campaign, we're passionate about making every piece of work count. Passionate storytellers and perfectionists by nature, the ability to tap into the feelings of any event makes us perfect partners for the next job with you.
  </p>

  <p className="paragraph-text">
  Want to tell your story? Let's do something extraordinary together. Whether it is promoting a brand, capturing a special moment, or even crafting a grand narrative, we can make that happen.
  </p>

  <p className="paragraph-text">
  Catch up over coffee and discuss ideas. Together, we can bring your vision to life.  </p>
</div>

        {/* </div> */}
      </div>
      <div className="influ-reel">Our Clients </div>
      <PartnerSlider />
      <div className="influ-reels">Case Study </div>
      <div
        className="slider"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="slider-container"
          style={{ animationPlayState: isPaused ? "paused" : "running" }}
        >
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
        </div>
      </div>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Link to="/contact" class="btn-flip mt-4">
          <button class="req-btn">
            <i class="animation"></i>GET IN TOUCH<i class="animation"></i>
          </button>
        </Link>
      </div>
      <h2 className="faq_hed">Frequently Asked Questions (FAQs)</h2>

      <details>
        <summary>
          Why should you develop a website according to industry trends?
        </summary>
        <ul>
          <li>
            Developing and maintaining your website according to ongoing trends
            is the best way to remain competitive in your business niche.
          </li>
        </ul>
      </details>
      <details>
        <summary>Can you work on a previously built website?</summary>
        <ul>
          <li>Yes, we can work on an existing website developed previously.</li>
        </ul>
      </details>
      <details>
        <summary>
          Why is it imperative to have a good website flow and design?
        </summary>
        <ul>
          <li>
            Layout plays a huge role in your website’s page. Without it, your
            website is a wall of text and links that few would care to read
            through. Layout is important because it influences many different
            things.
          </li>
        </ul>
      </details>
      <details>
        <summary>Why UI/UX is important for the website?</summary>
        <ul>
          <li>
            Responsive design teams create a single site to support many
            devices, but need to consider content, design and performance across
            devices to ensure usability.
          </li>
        </ul>
      </details>
      <ScrollToTopButton />
    </div>
  );
}

export default Production;
