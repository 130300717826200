import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./ServiceDetails.css";
import PartnerSlider from "../Home/PartnerSlider";
import netflix from "../../assets/images/case study.png";
import ScrollToTopButton from '../TopButton/TopButton';
import { Link } from "react-router-dom";


function WebsiteDesign() {
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    
    <div>
      <Helmet>
        <title>Website Design and Development Agency In Gurgaon - The Agency Way (TAW)</title>
        <meta name="description" content="Enhance your online presence with The Agency Way (TAW), your premier website design and development agency in Gurgaon. Transform your digital footprint with our expert solutions." />
        <link rel="canonical" href="https://taw.agency/services/website-design" />
      </Helmet>
      <div className="container">
        {/* <div className="row">
          <div className="col-lg-4 col-md-4 col-12 mt-lg-5">
            <img height="350px" width="350px" src={img} alt="Web & Design Development" />
          </div> */}
<div className="mt-5">
  <h1 className="service-details-title">
    Empower Your B2B, ECommerce Platform Development With TAW Experts
  </h1>
  <p className="paragraph-text">
    Let the revolutionary forces of the latest technology bring transformation into your B2B marketplace. The Agency Way (TAW), an accomplished name in developing e-commerce sites, has over two decades of experience powering businesses with state-of-the-art enterprise e-commerce platforms, a benchmark of innovation.
  </p>

  <h2 className="service-details-subtitle">
    Get Significant Boost to Your Business with B2B ECommerce Development Services
  </h2>
  <p className="paragraph-text">
    The Agency Way (TAW) is an ideal partner for designing a complete <a href="https://taw.agency/services/website-design" target="_blank" rel="noopener noreferrer">B2B and eCommerce solution</a> to solidify a suitable digital footprint for your brand. Our experience would enable your business to offer tailored and seamless experiences to your customers across all available platforms to help them get better engagement and satisfaction.
</p>

  <h3 className="service-details-subtitle">
    Our User-Friendly B2B Expertise and Development Services
  </h3>

  <h5>Custom eCommerce</h5>
  <p className="paragraph-text">
    We help you transform your online store into an optimized selling machine while handling all your specific business objectives and customer preferences. With years of experience in the niche, we help you focus on guaranteed engagement and conversion-rate-driven results.
  </p>

  <h5>Web Maintenance & Support</h5>
  <p className="paragraph-text">
    Stay worry-free while our dedicated support services attend to all the technical questions that may arise and get the platform up to speed with perfect performance by following the best practice guidelines in B2B eCommerce as envisaged and selected by your particular support package.
  </p>

  <h5>Website Designing</h5>
  <p className="paragraph-text">
    Inspire everything with our beautiful and visually appealing web designs. We focus on aesthetics and functionality to make your site look fantastic and user-friendly.
  </p>

  <h5>eComm Portal Development</h5>
  <p className="paragraph-text">
    E-commerce portal that performs at a high level: From cataloguing products to gateways for payments, we want to make it seamless for the customer to shop from your store.
  </p>

  <h5>Content Management System (CMS)</h5>
  <p className="paragraph-text">
    More streamlined digital operations with strong CMS solutions. Have the freshness of your website content to maintain a well-structured, coherent, and interactive online presence towards fulfilling the vision of your business.
  </p>

  <h5>Website Development</h5>
  <p className="paragraph-text">
    Build your digital dream using comprehensive website development services. Let's create a user-friendly, responsive website featuring industry-specific elements and targeting elements for your specific audience.
  </p>

  <h5>Micro-Sites</h5>
  <p className="paragraph-text">
    Reach your target with micro-sites perfect for events, promotions, or limited campaigns at the needed location. Compact and narrowly focused, these sites are built for results- the sooner, the better.
  </p>

  <h5>Web Application</h5>
  <p className="paragraph-text">
    Unlock productivity and efficient workflow for your business with customized web applications. All these tools, from dashboards to more engaging tools, have been designed according to your target goals.
  </p>

  <h5>UI Designing</h5>
  <p className="paragraph-text">
    Entice your customers with user-friendly, agile, and aesthetic UI designs that bring satisfaction to users. Our design team delivers seamless user navigation and engrossing experiences for all interactions.
  </p>

  <h2 className="service-details-subtitle">
    Evolve with Changing Market Dynamics, Deploy Scalable B2B eCommerce Solution
  </h2>
  <p className="paragraph-text">
    The pace at which eCommerce has evolved lately has been unprecedented. A well-designed eCommerce platform is the need of the hour to stay up with the dynamic change in customer behaviour and market demands.
  </p>

  <p className="paragraph-text">
    <a href="https://taw.agency/" target="_blank" rel="noopener noreferrer">The Agency Way (TAW)</a>, a solutions provider with more than 300 B2B project successes over two decades, is compatible with clients in various industries. Skilled B2B, eCommerce developers work with us to design unique, scalable, and user-centric marketplaces to meet the needs of your business. Trust us with the customized B2B and eCommerce solutions that streamline processes and drive growth for your business.
</p>
</div>

        {/* </div> */}
      </div>
      <div className="influ-reel">Our Clients </div>
      <PartnerSlider />
      <div className="influ-reels">Case Study </div>
      <div className="slider" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className="slider-container" style={{ animationPlayState: isPaused ? "paused" : "running" }}>
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
          <img className="slide" src={netflix} alt="Netflix" />
        </div>
      </div>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
      <Link to="/contact" class="btn-flip mt-4" >
          <button class="req-btn"><i class="animation"></i>GET IN TOUCH<i class="animation"></i>
          </button>
        </Link>
      </div>
      <h2 className="faq_hed">Frequently Asked Questions (FAQs)</h2>

      <details>
        <summary>
        Why website is important for businesses? 
        </summary>
        <ul>
          <li>
          Website creates a first point of contact with your customers, helping them to understand your business better.
          </li>
        </ul>
      </details>
      <details>
        <summary>
        What programming language is best for start-up?
        </summary>
        <ul>
          <li>
          Choosing programming language depends on the services you are offering and the length and level of your website. Basically, Shopify, HTML, WordPress, PHP are the most used programming language for early-stage businesses.          </li>
        </ul>
      </details>
      <details>
        <summary>
        How long does it take to design & develop a website?
        </summary>
        <ul>
          <li>
          Designing & Developing a website can take anywhere between a week to a month depending on the size, requirements and complexity of the website. 
          </li>
        </ul>
      </details>
      <details>
        <summary>
        How to build customized website to reflect brand identity?
        </summary>
        <ul>
          <li>
         Building a customized website is a perfect way to communicate your brand's values and offerings. You need to be consistent with the color palette, theme, messaging throughout the website.          </li>
        </ul>
      </details>
      <ScrollToTopButton />
    </div>
  );
}

export default WebsiteDesign;
